@tailwind base;
@tailwind components;
@tailwind utilities;

::selection{
  background-color: #36cf8f;
  color: #ffffff
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-hero-background {
  background-image:url('./assets/images/header.jpg');
  background-size: cover; /* Optional: Adjust based on your image requirements */
  background-position: center; /* Optional: Adjust based on your image requirements */
}
.bg-home-contact {
  background-image:url('./assets/images/header.jpg');
  background-size: cover; /* Optional: Adjust based on your image requirements */
  background-position: center; /* Optional: Adjust based on your image requirements */
}
.bg-home-contact:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(135deg, #111D29 0%, #25242B 100%);
  opacity: 0.77;
  z-index: 2;
}
.bg-home-doc {
  background-image:url('./assets/images/header.jpg');
  background-size: cover; /* Optional: Adjust based on your image requirements */
  background-position: center; /* Optional: Adjust based on your image requirements */
}
.bg-home-doc:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #555B64;
  opacity: 0.89;
  z-index: 2;
}

.section-bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 82px;
  z-index: 20;
  background: url("./assets/images/section-bottom.svg") center center no-repeat;
}
.section-top {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 82px;
  z-index: 20;
  background: url("./assets/images/section-top.svg") center center no-repeat;
}


